import axios from "axios";

const axiosClient = axios.create({
  baseURL: "https://treatcorner.in/",
});

axiosClient.interceptors.request.use(function (config) {
  const token =
    "Basic YXNzYW1kaWdpdGFsQGdtYWlsLmNvbTpEUURCIEpHcDMgQmpyOCBTeE5RIDZvSVggNThwWA==";
  config.headers.Authorization = token;

  return config;
});

export default axiosClient;
