import axiosClient from "../baseUrl/baseUrl";

export const LOGIN = async (email, password) => {
  try {
    const payLoad = {
      email: email,
      password: password,
    };

    const response = await axiosClient.post("/login", payLoad);
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const GET_DATA = async () => {
  try {
    const response = await axiosClient.get("/wp-json/enquiries/v1/all");
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const DELETE_DATA = async (id) => {
  try {
    const response = await axiosClient.get(
      `/wp-json/enquiries/v1/delete/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
