import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Logo from "../assets/images/tc-logo.png";
import { LogoutOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import Styles from "./Header.module.css";

const Header = () => {
  const { logout, isAuthenticated } = useAuth();

  return (
    <header className={Styles.header}>
      <div className={Styles.headerContent}>
        <img src={Logo} alt="Logo" className={Styles.logo} />

        <div className={Styles.userInfo}>
          {isAuthenticated && (
            <>
              <span className={Styles.userName}>
                <span
                  style={{
                    fontSize: "10px",
                    color: "violet",
                    lineHeight: "0",
                    display: "block",
                  }}
                >
                  Welcome,
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "red",
                    lineHeight: "0",
                  }}
                >
                  Treat Corner
                </span>
              </span>
              <LogoutOutlined className={Styles.icon} onClick={logout} />
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
