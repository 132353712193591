import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Styles from "./Dashboard.module.css";
import {
  TextField,
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { Button, Spin } from "antd";
import { ReloadOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import * as API from "../../services/api";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader, setLoader] = useState(false);

  const getData = async () => {
    setLoader(true);
    const response = await API.GET_DATA();
    const data = response ? response.reverse() : [];
    setData(data);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .replace(",", " - ");
  };

  const truncateMessage = (message, wordCount = 3) => {
    const words = message.split(" ");
    if (words.length > wordCount) {
      return words.slice(0, wordCount).join(" ") + "...";
    }
    return message;
  };

  const columns = [
    { field: "name", headerName: "Name", width: 130 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "mobile", headerName: "Mobile", width: 130 },
    { field: "city", headerName: "City", width: 130 },
    {
      field: "message",
      headerName: "Message",
      width: 200,
      renderCell: (params) => truncateMessage(params.value),
    },
    {
      field: "created_at",
      headerName: "Date & Time",
      width: 180,
      valueFormatter: (params) => formatDate(params),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            type="primary"
            size="small"
            onClick={() => handleViewDetails(params.row)}
          >
            Details
          </Button>
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => handleDelete(params.row.id)}
          />
        </>
      ),
    },
  ];

  const rows = data.map((item) => ({
    id: item.id,
    name: item.column_1,
    email: item.column_2,
    mobile: item.column_3,
    city: item.column_6,
    message: item.column_4,
    created_at: item.created_at,
  }));

  const filteredRows = rows.filter(
    (row) =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.mobile.includes(searchTerm)
  );

  const handleViewDetails = (row) => {
    setSelectedRow(row);
    setModalOpen(true);
  };

  const handleDelete = async (rowId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await API.DELETE_DATA(rowId);
        setData(data.filter((item) => item.id !== rowId));

        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRow(null);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className={StyleSheet.dashboardContainer}>
      {loader ? (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "grid",
            placeContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className={Styles.page_header}>
            <h1 className="py-1 px-2 bg-gradient-to-r from-purple-400 via-pink-500 to-pink-600 bg-clip-text text-transparent animate-gradient">
              Treat Corner - LMS
            </h1>
            <Button
              icon={<ReloadOutlined />}
              onClick={() => window.location.reload()}
            />
          </div>

          <TextField
            label="Search by Name, Email, or Mobile"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              checkboxSelection
              disableSelectionOnClick
            />
          </div>
        </>
      )}

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            User Details
          </Typography>
          {selectedRow && (
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {Object.entries(selectedRow).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {key === "created_at"
                          ? "Date & Time"
                          : key === "id"
                          ? ""
                          : key.charAt(0).toUpperCase() + key.slice(1)}
                      </TableCell>
                      <TableCell>
                        {key === "created_at"
                          ? formatDate(value)
                          : key === "id"
                          ? ""
                          : value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Dashboard;
