import React, { useState } from "react";
import Styles from "./Login.module.css";
import { Form, Input, Button, Card } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../../contexts/AuthContext";
import * as API from "../../services/api";
import Logo from "../../assets/images/tc-logo.png";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const onFinish = async (values) => {
    console.log(values);
    setLoading(true);
    try {
      //   const response = await API.LOGIN(values.email, values.password);
      if (
        // response.data.email === "user@gmail.com" &&
        // response.data.password === "password"
        values.email === "connect.treatconer@gmail.com" &&
        values.password === "Tc@1234$"
      ) {
        login();
        navigate("/dashboard");
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid credentials",
          text: "Plaese enter valid email and password",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Login failed",
        text: error.message,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    }
    setLoading(false);
  };

  return (
    <div className={Styles.login_container}>
      <div className={Styles.login_form_container}>
        <div className={Styles.login_logo}>
          <img src={Logo} alt="Logo" />
        </div>
        <Card className={`${Styles.login_card} h-full w-full`}>
          <h1 className={Styles.login_title}>Admin Panel</h1>
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
            className={`${Styles.login_form} login_form`}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                className={Styles.login_button}
                htmlType="submit"
                loading={loading}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
      <footer className={Styles.footer}>
        <p>
          This <strong>Lead Management System</strong> is an intellectual
          property of{" "}
          <span className="text-blue-400 no-underline hover:underline">
            <a href="https://treatcorner.in" target="_blank">
              Treat Corner
            </a>
          </span>{" "}
          . Malicious activity will result in legal action. IP tracking is
          enabled to prevent misuse.
        </p>
        <p className="text-sm my-2 ">
          Designed &amp; Developed by
          <strong className="py-1 px-2 bg-gradient-to-r from-purple-400 via-pink-500 to-pink-600 bg-clip-text text-transparent animate-gradient">
            <a href="https://assamdigital.com" target="_blank">
              Assam Digital
            </a>
          </strong>
        </p>
      </footer>
    </div>
  );
};

export default Login;
